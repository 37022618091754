import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~4],
		"/(authenticated)/classrooms/[readableId]": [~5,[2,3]],
		"/(authenticated)/classrooms/[readableId]/stories": [~6,[2,3]],
		"/(authenticated)/classrooms/[readableId]/stories/create": [~8,[2,3]],
		"/(authenticated)/classrooms/[readableId]/stories/[storyId]": [~7,[2,3]],
		"/(authenticated)/classrooms/[readableId]/students": [~9,[2,3]],
		"/(authenticated)/classrooms/[readableId]/students/[studentId]": [~10,[2,3]],
		"/(authenticated)/konohagakure": [~11,[2]],
		"/login": [~14],
		"/(authenticated)/playground": [12,[2]],
		"/privacy": [~15],
		"/(authenticated)/profile/setup": [~13,[2]],
		"/signup": [~16],
		"/terms": [~17]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';